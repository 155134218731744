import React, { useState } from 'react';
import './NotificationSwitch.scss'; // Ensure this stylesheet is properly configured for styling

interface NotificationSwitchProps {
  firstSwitch: string;
  secondSwitch: string;
  setFilter: (filter: string) => void;
  fullWidth?: boolean;
}

const NotificationSwitch: React.FC<NotificationSwitchProps> = ({ firstSwitch, secondSwitch, setFilter, fullWidth }) => {
  const [active, setActive] = useState(firstSwitch);

  const handleSwitch = (filter: string) => {
    setActive(filter);
    setFilter(filter);
  };

  return (
    <div className={`${fullWidth ? "w-full" : "w-[237px]"} h-9 p-1 bg-[#f9f9f9] rounded-[10px] flex justify-start items-center`}>
      <div 
        className={`${fullWidth ? "w-full" : "w-1/2"} h-7 pl-2 pr-[7.50px] py-1.5 rounded-md flex justify-center items-center cursor-pointer ${
          active === firstSwitch ? "bg-white border" : ""
        }`} 
        onClick={() => handleSwitch(firstSwitch)}
      >
        <div className={`${fullWidth ? "w-1/2" : "w-full"} h-4 text-center text-[13px] font-medium font-['Archivo'] leading-tight ${
          active === firstSwitch ? "text-[#0d0d0d]" : "text-[#0d0d0d]/60"
        }`}>
          {firstSwitch}
        </div>
      </div>
      <div 
        className={`${fullWidth ? "w-full" : "w-1/2"} h-7 pl-2 pr-[7.50px] py-1.5 rounded-md flex justify-center items-center cursor-pointer ${
          active === secondSwitch ? "bg-white border" : ""
        }`} 
        onClick={() => handleSwitch(secondSwitch)}
      >
        <div className={`${fullWidth ? "w-full" : "w-[99px]"} h-4 text-center text-[13px] font-medium font-['Archivo'] leading-tight ${
          active === secondSwitch ? "text-[#0d0d0d]" : "text-[#0d0d0d]/60"
        }`}>
          {secondSwitch}
        </div>
      </div>
    </div>
  );
};

export default NotificationSwitch;



//     setFilter(filter);
