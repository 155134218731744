import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

import "./CustomInputDropdown.scss";

const CustomInputDropdown: React.FC<CustomInputDropdownProps> = ({
  dataItems,
  label,
  control,
  name,
  isSearchable,
  setSelectedOption,
  selectedOption,
  isClearable = false,
  errorMessage,
  typedInput,
  isMulti = false,
  setTypedInput = () => {},
  menuPortalTarget = document.body, // Default to document.body
}) => {
  // const [customValue, setCustomValue] = useState<string>("");

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");
    setTypedInput(inputValue);
    return inputValue;
  };

  

  return (
    <div>
      <label htmlFor={label} className="mt-[4px] label">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            name={name}
            options={dataItems}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputValue={typedInput}
            onInputChange={handleInputChange}
            placeholder="Select or type..."
            isClearable={isClearable}
            isSearchable={isSearchable}
            isMulti={isMulti}
            menuPortalTarget={menuPortalTarget} // Pass the portal target here
          />
        )}
      />

      {errorMessage && (
        <p className="text-[#FF3B30] text-[12px]">{errorMessage}</p>
      )}
    </div>
  );
};

export default CustomInputDropdown;
