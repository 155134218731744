import React, { FC, useState, useEffect, useMemo } from "react";
import BulkPayoutLayout from "../../components/BulkPayoutLayout/BulkPayoutLayout";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "../../components/Table/Table";
import { PlusOutlined } from "@ant-design/icons";
import CustomInputDropdown from "../../components/UIKits/CustomInputDropdown/CustomInputDropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import { manualBulkPayoutSchema } from "../../validation-schemas/manual-bulk.schema";
import { Upload, Button } from "antd";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { VendorDetailType } from "../Payouts/types";
import { searchVendor } from "../../services/vendor";
import { useMutation, useQuery } from "react-query";
import CustomInput from "../../components/UIKits/CustomInput/CustomInput";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/checkmark.svg";
import { ReactComponent as GradientBackground } from "../../assets/icons/gradientbackground.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { ReactComponent as ConnectionErrorIcon } from "../../assets/icons/connection-error.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/Bill Cross.svg";
import { Modal } from "antd";
import PrimaryButton from "../../components/Button/PrimaryButton";
import OutlineButton from "../../components/OutlineButton/OutlineButton";
import { getTaxes, intiateBulkPayout } from "../../services/payout-request";
import { TaxItemType } from "../../components/PayoutCreationDrawer/types";
import { formatCurrency } from "../../utils/helper";
import { Checkbox, GetProp } from "antd";
import "./BulkPayoutManualEntry.scss";
import { ManualBulkPayoutRowData, chargeDataPayloadType } from "./types";
import StatusContainer from "../../components/StatusConatiner/StatusContainer";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
interface vendorSelectValue {
  value: string;
  label: string;
  // payeeId: string;
}

const BulkPayoutManualEntry = () => {
  const { payeeType } = useLocation().state || {};
  const navigate = useNavigate();
  const [showInitiateModal, setShowInitiateModal] = useState(false);
  const [closePayout, setClosePayout] = useState(false);
  const [status, setStatus] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [payload, setPayload] = useState({});
  const [selectedTaxes, setSelectedTaxes] = useState<number[]>([]);
  const [taxList, setTaxList] = useState([]);
  // const [totalExcludingTax, setTotalExcludingTax] = useState(0);
  const [totalTaxDeduction, setTotalTaxDeduction] = useState(0);
  const [totalAmountPayable, setTotalAmountPayable] = useState(0);
  const statuses = ["payoutSuccessful", "payoutFailed", "connectionError"];
  const getInitialPayeeType = () => {
    if (payeeType === "Vendor") return 1;
    if (payeeType === "Employee") return 2;
    return null;
  };
  const [filters, setFilter] = useState({
    Page: 1,
    PageSize: 4000,
    Status: 1,
    PayeeType: getInitialPayeeType(),
  });
  // React Hook Form and FieldArray initialization
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(manualBulkPayoutSchema),
    defaultValues: {
      tableData: [
        {
          payeeId: {},
          amountPayable: 0,
          vat: false,
          whtGeneralContract: false,
          whtConsultancyService: false,
          stampDuty: false,
          reason: "",
          supportingDocument: "",
          amount: 0,
        },
      ],
    },
  });
  const generalTableData = watch("tableData");

  // Total values
  const totalExcludingTax =
    generalTableData?.reduce(
      (sum, item) => sum + parseFloat(item.amount.toString()),
      0
    ) || 0;
  const generalTotalamountPayable =
    generalTableData?.reduce((sum, item) => sum + item.amountPayable, 0) || 0;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tableData",
  });

  // Fetch vendor options
  const { isLoading, data: response } = useQuery(
    ["vendorSearch", filters, payeeType],
    () => searchVendor(filters),
    {
      enabled: true,
      retry: false,
    }
  );
  const vendorList: VendorDetailType[] = response?.data?.items || [];

  const vendorOptions = isLoading
    ? []
    : vendorList.map((vendor: VendorDetailType) => ({
        label: vendor.name,
        value: vendor.payeeId,
        // payeeId: vendor.payeeId.toString(),
      }));

  // Fetch tax list

  const { isLoading: isRetrievingTaxList, data: taxData } = useQuery(
    "taxList",
    () => getTaxes(0),
    {
      onSuccess: (data) => {
        //@ts-ignore
        setTaxList(data?.data.map((tax) => ({
            label: tax.chargeTypeName,
            value: tax.chargeType,
          }))
        );
      },
    }
  );

  const getSelectedTaxes = (rowNumber: number) => {
    const list = [];

    if (generalTableData && generalTableData[rowNumber]?.stampDuty)
      list.push(4);
    if (generalTableData && generalTableData[rowNumber]?.vat) list.push(1);
    if (generalTableData && generalTableData[rowNumber]?.whtGeneralContract)
      list.push(2);
    if (generalTableData && generalTableData[rowNumber]?.whtConsultancyService)
      list.push(3);

    return list;
  };

  const getTotalChargeByType = (chargeType: number) => {
    const tax = taxData?.data.find(
      (taxSample: TaxItemType) => taxSample?.chargeType === chargeType
    );
    if (!tax) return 0;

    let totalCharge = 0;

    generalTableData?.forEach((item) => {
      if (
        (chargeType === 1 && item.vat) ||
        (chargeType === 2 && item.whtGeneralContract) ||
        (chargeType === 3 && item.whtConsultancyService) ||
        (chargeType === 4 && item.stampDuty)
      ) {
        totalCharge +=
          (parseFloat(item?.amount?.toString()) *
            parseFloat(tax.chargePercentage)) /
          100;
      }
    });

    return totalCharge;
  };

  // Calculate totals for each charge type
  const totalVat =
    isRetrievingTaxList || !generalTableData ? 0 : getTotalChargeByType(1);
  const totalStampDuty =
    isRetrievingTaxList || !generalTableData ? 0 : getTotalChargeByType(4);
  const totalWhtConsultancyService =
    isRetrievingTaxList || !generalTableData ? 0 : getTotalChargeByType(3);
  const totalWhtGeneralContract =
    isRetrievingTaxList || !generalTableData ? 0 : getTotalChargeByType(2);

  const calculateRowAmountPayable = (
    amount: number,
    selectedTaxes: number[]
  ) => {
    let taxDeduction = 0;
    taxData?.data?.forEach((tax: TaxItemType) => {
      //@ts-ignore
      if (selectedTaxes?.includes(tax.chargeType)) {
        taxDeduction += (amount * parseFloat(tax.chargePercentage)) / 100;
      }
    });
    return amount - taxDeduction;
  };

  // Handle Tax
  const calculateAmountPayable = (
    baseAmount: number,
    selectedTaxes: number[]
  ) => {
    let totalAmount = baseAmount;

    selectedTaxes.forEach((taxId) => {
      const tax = taxData?.data?.find((t: any) => t.chargeType === taxId);
      if (tax) {
        totalAmount -= (baseAmount * parseFloat(tax.chargePercentage)) / 100;
      }
    });

    return totalAmount;
  };

  useEffect(() => {
    if (fields.length === 0) {
      addRow();
    }
    calculateTotals();
  }, [fields]);

  const addRow = () => {
    append({
      payeeId: { value: "" },
      amountPayable: 0,
      vat: false,
      whtGeneralContract: false,
      whtConsultancyService: false,
      stampDuty: false,
      reason: "",
      supportingDocument: "",
      amount: 0,
    });
  };
  const { mutate: mutateAsync, isLoading: isSubmitingData } = useMutation(
    intiateBulkPayout,
    {
      onSuccess: (response) => {
        setStatus("payoutSuccessful");
        setSuccessMessage(response.message);
        // toast.success("Payout initiated successfully!");
        setShowInitiateModal(false); // Close the modal on success
      },
      onError: (error: AxiosError<{ message: string }>) => {
        if (error?.response) {
          setStatus("payoutFailed");
          setErrorMessage(error?.response?.data?.message);
          // toast.error(
          //   `Failed to initiate payout: ${error?.response?.data?.message}`
          // );
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      },
    }
  );

  const onSubmit = async (data: any) => {
    const payouts = generalTableData?.map((item) => {
      // Initialize charges and taxPayable
      let charges: chargeDataPayloadType[] = [];
      let totalTax = 0;

      // Helper to add charge if applicable
      const addCharge = (condition: boolean, tax: TaxItemType) => {
        if (condition) {
          const calculatedCharge = parseFloat(
            ((item.amount * parseFloat(tax?.chargePercentage)) / 100).toFixed(2)
          );
          totalTax += calculatedCharge;
          charges.push({
            chargeType: tax.chargeType,
            chargePercentage: tax.chargePercentage.toString(),
            calculatedCharge: parseFloat(calculatedCharge.toFixed(2)),
          });
        }
      };

      // Apply each tax condition
      payeeType === "Vendor" &&
        item?.vat &&
        addCharge(
          item?.vat,
          taxData?.data.find((tax: TaxItemType) => tax.chargeType === 1)
        );
      payeeType === "Vendor" &&
        item?.whtGeneralContract &&
        addCharge(
          item?.whtGeneralContract,
          taxData?.data.find((tax: TaxItemType) => tax.chargeType === 2)
        );
      payeeType === "Vendor" &&
        item?.whtConsultancyService &&
        addCharge(
          item?.whtConsultancyService,
          taxData?.data.find((tax: TaxItemType) => tax.chargeType === 3)
        );
      payeeType === "Vendor" &&
        item?.stampDuty &&
        addCharge(
          item?.stampDuty,
          taxData?.data.find((tax: TaxItemType) => tax.chargeType === 4)
        );

      return {
        payeeId: item.payeeId?.value,
        amount: parseFloat(item.amount.toString()),
        reason: item.reason,
        documents: item.supportingDocument || [],
        taxPayable: totalTax,
        charges: payeeType === "Vendor" ? charges : [],
      };
    });
    const currentPayload = {
      payeeType: payeeType === "vendor" ? 1 : 2,
      payouts: payouts,
    };
    setPayload(currentPayload);
    mutateAsync(currentPayload);
  };

  const handleInitiateProcess = () => {
    setShowInitiateModal(true);
  };

  const closePayoutProcess = () => {
    setClosePayout(true);
  };

  // Handle selected tax
  const handleTaxChange = (rowIndex: number, taxType: string) => {
    // @ts-ignore
    setValue(`tableData[${rowIndex}].${taxType}`, true);
    // @ts-ignore
    const amount = parseFloat(watch(`tableData[${rowIndex}].amount`) || "0");
    const allSelectedTaxes = getSelectedTaxes(rowIndex);
    // @ts-ignore
    const amountPayable = calculateRowAmountPayable(amount, allSelectedTaxes);
    // @ts-ignore
    setValue(`tableData[${rowIndex}].amountPayable`, amountPayable);
    calculateTotals();
  };

  const calculateTotals = () => {
    const tableData = watch("tableData");
    let newTotalExcludingTax = 0;
    let newTotalTaxDeduction = 0;
    let newTotalAmountPayable = 0;

    tableData?.forEach((row: any) => {
      newTotalExcludingTax += row.amount || 0;
      newTotalAmountPayable += row.amountPayable || 0;
    });

    newTotalTaxDeduction = newTotalExcludingTax - newTotalAmountPayable;

    // setTotalExcludingTax(newTotalExcludingTax);
    setTotalTaxDeduction(newTotalTaxDeduction);
    setTotalAmountPayable(newTotalAmountPayable); // Add this line
  };

  const renderCheckboxGroup = (rowIndex: number, taxType: string) => {
    const filteredTaxList = taxList.filter((tax: any) => {
      switch (taxType) {
        case "vat":
          return tax.label.startsWith("VAT");
        case "whtGeneralContract":
          return tax.label.includes("WHT") && tax.label.includes("5%");
        case "whtConsultancyService":
          return tax.label.includes("WHT") && tax.label.includes("10%");
        case "stampDuty":
          return tax.label.includes("Stamp Duty");
        default:
          return false;
      }
    });

    return (
      <Checkbox.Group
        options={filteredTaxList}
        //@ts-ignore
        // value={watch(`tableData[${rowIndex}].${taxType}`) || []}
        //@ts-ignore
        onChange={(checkedValues) =>
          handleTaxChange(rowIndex, taxType)
        }
      />
    );
  };
  const builder = (
    field: any,
    item: any,
    rowIndex: number,
    columnIndex: number
  ) => {
    switch (field.name) {
      case "vendorName":
        return (
          <div className="w-[200px] overflow-visible z-50">
            <CustomInputDropdown
              name={`tableData[${rowIndex}].payeeId`}
              dataItems={vendorOptions}
              label=""
              isSearchable={true}
              isClearable={true}
              control={control}
              errorMessage={errors?.tableData?.[rowIndex]?.payeeId?.value?.message}
              menuPortalTarget={document.body} // Ensures dropdown renders outside the scroll container
            />
          </div>
        );
      case "amount":
        return (
          <div className="w-[100px]">
            <CustomInput
              type="number"
              control={control}
              name={`tableData[${rowIndex}].amount`}
              placeholder="0.00"
              //@ts-ignore
              handleOnChange={(value) => {
                const amount = parseFloat(value);
                const allSelectedTaxes = getSelectedTaxes(rowIndex);
                //@ts-ignore
                const amountPayable = calculateAmountPayable(
                  amount,
                  allSelectedTaxes
                );
                //@ts-ignore
                setValue(`tableData[${rowIndex}].amountPayable`, amountPayable);
                calculateTotals();
              }}
              errorMessage={errors?.tableData?.[rowIndex]?.amount?.message}
            />
          </div>
        );
      case "vat":
        return renderCheckboxGroup(rowIndex, "vat");
      case "whtGeneralContract":
        return renderCheckboxGroup(rowIndex, "whtGeneralContract");
      case "whtConsultancyService":
        return renderCheckboxGroup(rowIndex, "whtConsultancyService");
      case "stampDuty":
        return renderCheckboxGroup(rowIndex, "stampDuty");
      case "reason":
        return (
          <div className="w-[200px]">
          <CustomInput
            name={`tableData[${rowIndex}].reason`}
            type="text"
            control={control}
            placeholder="Reason"
            errorMessage={errors?.tableData?.[rowIndex]?.reason?.message ?? ''}
          />
          </div>
        );
      // case "document":
      //   return (
      //     <Upload>
      //       <Button>Add Document</Button>
      //     </Upload>
      //   );
      case "document":
        const handleFileUploadChange: UploadProps["onChange"] = (info) => {
          if (info.file.status === 'done' || info.file.status === 'uploading') {
            // Show success toast if the file has been successfully uploaded
            toast.success(`${info.file.name} file uploaded successfully`);
            const reader = new FileReader();
            reader.onload = (e) => {
              // Convert file to Base64 and store it in the tableData
              const base64 = e.target?.result as string;
              const base64Data = base64.split(',')[1];
              //@ts-ignore
              setValue(`tableData[${rowIndex}].supportingDocument`, [base64Data]);
            };
            reader.readAsDataURL(info.file.originFileObj as Blob);
          } else if (info.file.status === "error") {
            // toast.error(`${info.file.name} file upload failed.`);
          }
        };
 
        return (
          <Upload
            maxCount={1}
            accept=".csv,.pdf"
            action= "" // Prevents default upload to localhost
            beforeUpload={(file) => {
              const isLt2M = file.size / 1024 / 1024 < 2;
              if (!isLt2M) {
                toast.error("File must be smaller than 2MB!");
              }
              return isLt2M; // Only allow upload if file is under 2MB
            }}
            onChange={handleFileUploadChange} // Using the defined handler for file upload change
          >
            <Button>Add Document</Button>
          </Upload>
        );
      case "amountPayable":
        return (
          <CustomInput
            isDisabled
            type="number"
            name={`tableData[${rowIndex}].amountPayable`}
            control={control}
            placeholder=""
          />
        );
      case "actions":
        return (
          <DeleteIcon
            className="fill-[#0D0D0D]"
            onClick={() => remove(rowIndex)}
            style={{ color: "#5f38fa", cursor: "pointer" }}
          />
        );
      default:
        return null;
    }
  };

  const renderIconAndStatus: any = useMemo(() => {
    switch (status) {
      case "payoutSuccessful":
        return {
          icon: (
            <>
              <CheckMarkIcon />
              <GradientBackground />
            </>
          ),
          status: "success",
          title: "Payout initiated",
          description:
            "An approver will be required to approve this action before the payout is completed",
        };
      case "connectionError":
        return {
          icon: <ConnectionErrorIcon />,
          status: "warning",
          title: "Connection Error",
          description:
            "Sorry, we lost connection. Your computer may be offline or we may be experiencing some problemsWe will automatically try to reconnect",
          showBackButton: true,
          actionText: "Retry",
          onRetry: () => {
            mutateAsync(payload);
          },
        };
      default:
        return {
          icon: <ErrorIcon />,
          status: "error",
          title: "Action Failed",
          description:
            errorMessage ||
            "An error occurred while trying to initiate this payout",
          showBackButton: true,
          actionText: "Retry",
          onRetry: () => {
            mutateAsync(payload);
          },
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, successMessage, payload, status]);

  useEffect(() => {
    console.log("Form errors:", errors);
  }, [errors]);

  useEffect(() => {
    setFilter(prevFilters => ({
      ...prevFilters,
      PayeeType: getInitialPayeeType(),
    }));
  }, [payeeType]);
  
  console.log(payeeType, "type");
  
  return (
    <BulkPayoutLayout
      pageHeading="New Bulk Payment"
      additionalClassName="manual-payout-container"
      topCancelButtonAction={() => navigate("/bulk-payout")}
      continueButtonAction={handleInitiateProcess}
      bottomCancelButtonAction={closePayoutProcess}
      showButtomButtons={status === ""}>
      {statuses.includes(status) ? (
        <StatusContainer
          heading={renderIconAndStatus?.title}
          buttonText={renderIconAndStatus?.actionText}
          description={renderIconAndStatus?.description}
          children={renderIconAndStatus?.children}
          status={renderIconAndStatus?.status}
          setOpenDrawer={() => navigate("/bulk-payout")}
          closeFunction={() => {
            navigate("/bulk-payout");
          }}
          CustomBackAction={() => setStatus("")}
          icon={renderIconAndStatus?.icon}
          showBackButton={renderIconAndStatus?.showBackButton}
          onRetry={renderIconAndStatus?.onRetry}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-10">
            <div className="w-[267px] text-[#0d0d0d] text-2xl font-semibold font-['Archivo'] leading-[28.80px]">
              Initiate a payment for your {payeeType}s{" "}
            </div>
            {/* Table */}
            <div className="mt-8 main-table-wrapper">
              <Table
                tableData={fields}
                fields={[
                  { name: "vendorName", displayName: "Name" },
                  { name: "amount", displayName: "Amount" },
                  ...(payeeType === "Vendor"
                    ? [
                        { name: "vat", displayName: "VAT (7.5%)" },
                        { name: "whtGeneralContract", displayName: "WHT (5%)" },
                        {
                          name: "whtConsultancyService",
                          displayName: "WHT (10%)",
                        },
                        { name: "stampDuty", displayName: "Stamp duty (1%)" },
                      ]
                    : []),
                  { name: "reason", displayName: "Reason" },
                  { name: "document", displayName: "Document" },
                  { name: "amountPayable", displayName: "Amount payable" },
                  { name: "actions", displayName: "Actions" },
                ]}
                builder={builder}
                columns={fields.length}
                rows={fields.length}
              />
            </div>
            <div
              onClick={addRow}
              style={{
                cursor: "pointer",
                color: "#8c8c8c",
                fontWeight: "bold",
                textAlign: "center",
                margin: "16px 0",
              }}>
              <PlusOutlined /> Add item
            </div>

            <hr className=" border-dashed my-4" />
            <div className="w-full flex justify-end pb-5 border-b">
              <div className="w-full md:w-[550px]">
                <p className="tax-info">
                  <span>Total excluding tax</span>
                  <span>{formatCurrency(totalExcludingTax || 0)}</span>
                </p>
                {taxData?.data?.map((tax: TaxItemType) => {
                  const taxAmount = selectedTaxes[tax.chargeType] || 0;
                  return taxAmount > 0 ? (
                    <p className="tax-info" key={tax.chargeType}>
                      <span>
                        {tax.chargeTypeName} ({tax.chargePercentage}%)
                      </span>
                      <span>-{formatCurrency(taxAmount)}</span>
                    </p>
                  ) : null;
                })}
                {payeeType === "Vendor" &&
                  generalTableData &&
                  generalTableData?.filter((item) => item.vat === true).length >
                    0 && (
                    <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                      <span>VAT (7.5%)</span>
                      <span>{formatCurrency(totalVat || 0)}</span>
                    </p>
                  )}
                {payeeType === "Vendor" &&
                  generalTableData &&
                  generalTableData?.filter(
                    (item) => item.whtGeneralContract === true
                  ).length > 0 && (
                    <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                      <span>WTH (5%)</span>
                      <span>
                        {formatCurrency(totalWhtGeneralContract || 0)}
                      </span>
                    </p>
                  )}
                {payeeType === "Vendor" &&
                  generalTableData &&
                  generalTableData?.filter(
                    (item) => item.whtConsultancyService === true
                  ).length > 0 && (
                    <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                      <span>WTH (10%)</span>
                      <span>
                        {formatCurrency(totalWhtConsultancyService || 0)}
                      </span>
                    </p>
                  )}
                {payeeType === "Vendor" &&
                  generalTableData &&
                  generalTableData?.filter((item) => item.stampDuty === true)
                    .length > 0 && (
                    <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                      <span>Stamp duty (1%)</span>
                      <span>{formatCurrency(totalStampDuty || 0)}</span>
                    </p>
                  )}
                <hr className=" border-dashed my-4" />
                <p className="flex justify-between text-[#0D0D0D] text-xl font-medium">
                  <span>Amount payable</span>{" "}
                  <span>{formatCurrency(generalTotalamountPayable || 0)}</span>
                </p>
              </div>
            </div>
            {showInitiateModal && (
              <Modal
                title={
                  <p className="text-2xl font-semibold">Initiate Payout?</p>
                }
                open={showInitiateModal}
                onCancel={() => {
                  setShowInitiateModal(false);
                }}
                onOk={() => setShowInitiateModal(false)}
                footer={null}>
                <p className="text-[#0D0D0D99] mt-6 mb-10">
                  Are you sure you want to initiate this payout? You won’t be
                  able to make any changes once you do so. Please review all
                  details before proceeding
                </p>
                <div className="flex justify-end gap-3 items-center">
                  <OutlineButton
                    onClick={() => {
                      setShowInitiateModal(false);
                    }}
                    label={"Cancel"}
                    className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block"
                  />
                  <PrimaryButton
                    onClick={handleSubmit(onSubmit)}
                    label={isSubmitingData ? "Submitting..." : "Payout"}
                    className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block  !text-white focus:shadow-none"
                  />
                </div>
              </Modal>
            )}

            {closePayout && (
              <Modal
                title={<p className="text-2xl font-semibold">Close Payout?</p>}
                open={closePayout}
                onCancel={() => {
                  setClosePayout(false);
                }}
                onOk={() => setClosePayout(false)}
                footer={null}>
                <p className="text-[#0D0D0D99] mt-6 mb-10">
                  You will lose all items entered on this payment if you choose
                  to close now
                </p>
                <div className="flex justify-end gap-3 items-center">
                  <OutlineButton
                    onClick={() => {
                      setClosePayout(false);
                    }}
                    label={"Cancel"}
                    className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block"
                  />
                  <PrimaryButton
                    onClick={() => navigate("/initiate-bulk-payout")}
                    label={"Close Payout"}
                    className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block  !text-white focus:shadow-none"
                  />
                </div>
              </Modal>
            )}
          </div>
        </form>
      )}
    </BulkPayoutLayout>
  );
};

export default BulkPayoutManualEntry;
