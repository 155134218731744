import { devInstance } from "../utils/axios/axiosInstance"

export const fetchWorkflowProcesses = async (payload: any) => {
    const response = await devInstance.get('/WorkFlow/processes')
    return response
}


export const searchRequest = async (payload: any) => {
    const response = await devInstance.get('/Request', {params: payload})

    return  response.data
}

export const getRequestById = async (id: any) => {
    const response = await devInstance.get(`/Request/${id}`)

    return  response.data
}


export const postRequest = async (payload: any) => {
    const response = await devInstance.post('/Request', payload)
    return  response.data
}


export const putRequestService = async (payload: any) => {
    const response = await devInstance.patch('/Request', payload)
    return  response.data
}


export const searchPayout = async (payload: any) => {
    const response = await devInstance.get('/Payout', {params: payload})

    return  response.data
}

export const intiatePayout = async (payload: any) => {
    const response = await devInstance.post('/Payout/InitiatePayout', payload)
    return  response.data
}

export const downloadPayout = async (payload: any) => {
    const response = await devInstance.get('/Payout/Download', {params: payload})  
    return response.data; 
};

export const downloadRequest = async (payload: any) => {
    const response = await devInstance.get('/Request/Download', {params: payload})  
    return response.data; 
};
export const getTaxes = async (payload: any) => {
    const response = await devInstance.get(`/Payout/GetTaxes?Amount=${payload}`)
    return response.data;
};
export const getApproverList = async () => {
    const response = await devInstance.get(`/Request/mentions`)
    return response.data;
};

export const searchBulkPayout = async (payload: any) => {
    const response = await devInstance.get('/Payout/BulkSearch', {params: payload})

    return  response.data
}

export const downloadBulkPayout = async (payload: any) => {
    const response = await devInstance.get('/Payout/BulkSearchDownload', {params: payload})  
    return response.data; 
};

export const intiateBulkPayout = async (payload: any) => {
    const response = await devInstance.post('/Payout/InitiateBulkPayout', payload)
    return  response.data
}