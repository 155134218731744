import "./BulkPayoutDetailDrawer.scss";
import { SideDrawer } from "../SideDrawer/SideDrawer";
import OutlineButton from "../OutlineButton/OutlineButton";

import { useState } from "react";
import DetailRow from "../PayoutDetailDrawer/DetailRow/DetailRow";
import RenderBankWithIcon from "../PayoutDetailDrawer/RenderBankWithIcon/RenderBankWithIcon";
import { ReactComponent as DocumentIcon } from "../../assets/icons/documenticon2.svg";
import StatusSetter from "../StatusSetter/StatusSetter";
import { getPayoutStatusText } from "../../utils/getPayoutStatusText";
import RenderIntialsFromVendorName from "../RenderIntialsFromVendorName/RenderIntialsFromVendorName";
import { TPayout } from "../../pages/Payouts/types";
import {
  capitalizeChargeKeys,
  formatCurrency,
} from "../../utils/helper";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowleft.svg";
import { ReactComponent as CloseOutlined } from "../../assets/icons/cancelicon.svg";
import TaxBreakDownModal from "../PayoutTable/TaxBreakDownModal/TaxBreakDownModal";
import TaxBreakDownText from "../PayoutTable/TaxBreakDownText/TaxBreakDownText";
import { payeeStatuses } from "../../config/enum/common.enum";
import SupportDocumentModal from "../SupportDocumentModal/SupportDocumentModal";

interface PayoutDetailDrawerType {
  openDraw: boolean;
  setOpenDrawer: (openDraw: boolean) => void;
  selectedPayoutToShow: TPayout; 
}

const BulkPayoutDetailDrawer = ({
  openDraw,
  setOpenDrawer,
  selectedPayoutToShow,
}: PayoutDetailDrawerType) => {
  const [showSupportingDocumentModal, setShowSupportingDocumentModal] =
    useState(false);

  const [showTaxModal, setShowTaxModal] = useState(false);

  const showPopupModal = () => {
    setShowTaxModal(true);
  };

  const tableValueArray = Array.isArray(selectedPayoutToShow?.tableValue) ? selectedPayoutToShow.tableValue : [];
  const numberOfPayees = tableValueArray.length;
//@ts-ignore
  const payeeTypeName = numberOfPayees > 0 ? tableValueArray[0]?.payeeTypeName : "";

  return (
    <SideDrawer
      isOpen={openDraw}
      setIsOpen={setOpenDrawer}
      showCloseButton={false}>
      <div className="payout-container">
        <div className="payout-header">
          <div>
            <h2>Payout details</h2>
            <StatusSetter
              incomingStatus={selectedPayoutToShow?.status}
              statusLabel={getPayoutStatusText(selectedPayoutToShow?.status)}
            />
          </div>
          <button
            className="cancel-button"
            onClick={() => setOpenDrawer(false)}>
            <CloseOutlined className="x-icon !hidden md:!block" />
            <ArrowLeft className="arrow-icon !block md:!hidden" />
          </button>
        </div>
        <div className="payout-detail">
          <div className="detail-intro">
            <h2 className="transaction-id">
              {selectedPayoutToShow?.referenceNumber}
              <span className="mobile-status">
                <StatusSetter
                  incomingStatus={selectedPayoutToShow?.status}
                  statusLabel={getPayoutStatusText(selectedPayoutToShow?.status)}
                />
              </span>
            </h2>
            <p className="transaction-id-text">
              {/* <span>Payout to</span> {selectedPayoutToShow?.vendorName}{" "} */}
              <span>Payout to</span> {numberOfPayees} {payeeTypeName}{numberOfPayees !== 1 ? "s" : ""}
              <span className="circle"></span>N{selectedPayoutToShow?.amount}
            </p>
          </div>
          <div className="detail-info">
            <DetailRow
              title="Payout to"
              value={
                <RenderIntialsFromVendorName
                  vendor_name={selectedPayoutToShow?.payees || ""}
                  isBulkPayout={true}
                  showViewMore ={true}
                  payeeList={selectedPayoutToShow?.tableValue}
                />
              }
            />
            <DetailRow
              title="Payee type"
              value={
                <span className="capitalize">
                  {payeeTypeName || "N/A"}
                </span>
              }
            />
            <DetailRow
              title="Amount"
              value={formatCurrency(selectedPayoutToShow?.amount)}
            />
            <DetailRow
              title="Tax payable"
              value={
                <div className="text-[#0D0D0D66] w-fit flex gap-2 items-center">
                  {selectedPayoutToShow?.charges &&
                  selectedPayoutToShow?.taxPayable ? (
                    <TaxBreakDownText
                      taxChargeList={capitalizeChargeKeys(selectedPayoutToShow?.charges) || []}
                      handleModalPopup={showPopupModal}
                    />
                  ) : null}
                  {formatCurrency(selectedPayoutToShow?.taxPayable)}
                </div>
              }
            />
            {/* <DetailRow
              title="Total excluding tax"
              value={formatCurrency(
                selectedPayoutToShow?.amount - selectedPayoutToShow?.taxPayable
              )}
            /> */}
            {/* <DetailRow
              title="Bank name"
              value={
                <RenderBankWithIcon
                  bankName={selectedPayoutToShow?.bankName || "N/A"}
                />
              }
            /> */}
            {/* <DetailRow
              title="Account name"
              value={selectedPayoutToShow?.accountName || "N/A"}
            /> */}
            {/* <DetailRow
              title="Account number"
              value={selectedPayoutToShow.accountNumber || "N/A"}
            /> */}
            {/* <DetailRow title="Reason" value={selectedPayoutToShow?.reason} /> */}
            {selectedPayoutToShow?.documentLink ? (
              <DetailRow
                title="Supporting documents"
                value={
                  <div className="py-1 px-0.5">
                    <OutlineButton
                      onClick={()=>setShowSupportingDocumentModal(true)}
                      icon={<DocumentIcon />}
                      label={"Click to view"}
                      className="font-medium !py-1.5 text-nowrap !text-sm "
                    />
                  </div>
                }
              />
            ) : null}
          </div>
        </div>
      </div>
      {selectedPayoutToShow?.charges && selectedPayoutToShow?.taxPayable && (
        <TaxBreakDownModal
        amountPaid={selectedPayoutToShow?.amount}
        amountTaxable={selectedPayoutToShow?.taxPayable}
        taxChargeList={
          capitalizeChargeKeys(selectedPayoutToShow?.charges) || []
        }
        showTaxBreakDownModal={showTaxModal}
        setShowTaxBreakDownModal={setShowTaxModal}
      />
      )}
      {selectedPayoutToShow?.documentLink && (
        <SupportDocumentModal
          showSupportingDocumentModal={showSupportingDocumentModal}
          setShowSupportingDocumentModal={setShowSupportingDocumentModal}
          documentLink={selectedPayoutToShow?.documentLink}
        />
      )}
      
    </SideDrawer>
  );
};

export default BulkPayoutDetailDrawer;
