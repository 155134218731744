import { devInstance } from "../utils/axios/axiosInstance";

export const downloadBulkPayoutTemplate = async (payload: any) => {
  const response = await devInstance.get("/Payout/SampleTemplate", {
    params: payload,
  });
  return response.data;
};

export const bulkPayoutFileUpload = async (payload: {
  payeeType: number;
  base64File: string;
}) => {
  const response = await devInstance.post(
    `/Payout?payeeType=${payload?.payeeType}&base64File=${payload?.base64File}`,
    { params: payload }
  );
  return response.data;
};

export const validateBulkPayeeDetail = async (payload: any) => {
  const response = await devInstance.post(
    `/Payout/ValidateUpload?payeeType=${payload?.payeeType}`,
    payload?.payeeDetails
  );
  return response.data;
};

export const initateBulkPayout = async (payload: any) => {
  const response = await devInstance.post(
    `/Payout/InitiateBulkPayout`,
    payload
  );
  return response.data;
};
