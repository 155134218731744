import * as yup from "yup";

export const bulkPayoutReviewSchema = yup.object().shape({
  payeeId: yup.string().required("Payee ID is required"),
  payeeName: yup.string().required("Payee Name is required"),
  beneficiaryBankCode: yup.string().required("Bank Code is required"),
  accountNumber: yup.string().required("Account number is required"),
  payableAmount: yup.number().required("Amount number is required").typeError("Invalid Amount").min(1, "Amount must be greater than 0"),
  reason: yup.string().required("Reason is required").trim(),
});
