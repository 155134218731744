import { Dispatch, useState } from "react";
import { SideDrawer } from "../SideDrawer/SideDrawer";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowleft.svg";
import { ReactComponent as CloseOutlined } from "../../assets/icons/cancelicon.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/cautionIcon.svg";
import { Spinner } from "../../components/UIKits/Spinner/Spinner";
import { BulkPayoutReviewRowData } from "../../pages/BulkPayoutReview/types";
import CustomInput from "../UIKits/CustomInput/CustomInput";
import FileUpload from "../FileUpload/FileUpload";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { bulkPayoutReviewSchema } from "../../validation-schemas/bulk-payout-review.schema";
import OutlineButton from "../OutlineButton/OutlineButton";
import PrimaryButton from "../Button/PrimaryButton";
import { Checkbox, GetProp } from "antd";
import { useMutation, useQuery } from "react-query";
import { getTaxes } from "../../services/payout-request";
import { TaxItemType } from "../PayoutCreationDrawer/types";
import { validateBulkPayeeDetail } from "../../services/bulk-payout.service";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import "./BulkPayoutReviewSideDrawer.scss";
import StatusContainer from "../StatusConatiner/StatusContainer";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/checkmark.svg";
import { ReactComponent as GradientBackground } from "../../assets/icons/gradientbackground.svg";

interface BulkPayoutReviewSideDrawerType {
  openDraw: boolean;
  setOpenDrawer: Dispatch<React.SetStateAction<boolean>>;
  selectedRowData?: BulkPayoutReviewRowData;
  setSelectedRowData: Dispatch<
    React.SetStateAction<BulkPayoutReviewRowData | null | undefined>
  >;
  payeeType: string;
  updateRowEntry: (newRowData: BulkPayoutReviewRowData) => void;
}

interface UploadedFileType {
  file: File;
  name: string;
  size: number;
  type: string;
  base64Format: string;
}

const BulkPayoutReviewSideDrawer = ({
  openDraw,
  setOpenDrawer,
  selectedRowData,
  setSelectedRowData,
  payeeType,
  updateRowEntry,
}: BulkPayoutReviewSideDrawerType) => {
  //   const [supportingDocument, setSupportingDocument] = useState<
  //     UploadedFileType[]
  //   >([]);

  const [isSuccess, setIsSuccess] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(bulkPayoutReviewSchema),
    defaultValues: {
      payeeId: selectedRowData?.payeeId || "",
      payeeName: selectedRowData?.payeeName || "",
      beneficiaryBankCode: selectedRowData?.beneficiaryBankCode || "",
      accountNumber: selectedRowData?.accountNumber || "",
      payableAmount: selectedRowData?.payableAmount || 0,
      reason: selectedRowData?.reason || "",
    },
  });

  const watchPayeeId = watch("payeeId");
  const watchPayeeName = watch("payeeName");
  const watchBeneficiaryBankCode = watch("beneficiaryBankCode");
  const watchAccountNumber = watch("accountNumber");
  const watchPayableAmount = watch("payableAmount");
  const watchReason = watch("reason");

  const closeDrawer = () => {
    setOpenDrawer(false);
    setSelectedRowData(null);
  };

  const defaultSelectedTaxes = (data: BulkPayoutReviewRowData) => {
    const list = [];

    if (data.stampDuty) list.push(4);
    if (data.vat) list.push(1);
    if (data.whtGeneralContract) list.push(2);
    if (data.whtConsultancyService) list.push(3);

    return list;
  };

  const [selectedTaxes, setSelectedTaxes] = useState<number[]>(
    defaultSelectedTaxes(selectedRowData!)
  );

  // Get TaxList
  const { isLoading: isRetrievingTaxList, data: incomingTaxList } = useQuery(
    [],
    () => getTaxes(0),
    {
      enabled: true,
      retry: false,
    }
  );

  const taxList = incomingTaxList
    ? incomingTaxList?.data?.map((taxInfo: TaxItemType) => ({
        label: (
          <div className="check-item">
            <span className="main-text">{taxInfo.chargeTypeName}</span>{" "}
            <span className="description-text">
              {taxInfo?.chargeTypeDescription || "Tax"}
            </span>{" "}
          </div>
        ),
        value: taxInfo?.chargeType,
      }))
    : [];

  // Handle selected tax
  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = (
    checkedValues
  ) => {
    setSelectedTaxes(checkedValues as number[]);
  };

  const { mutate: mutateAsync, isLoading: isSubmitingData } = useMutation(
    validateBulkPayeeDetail,
    {
      onSuccess: (response) => {
        if (response?.result?.dataItems[0]?.errorExists === true) {
          toast?.error(
            `Payee detail updated however, ${response?.result?.dataItems[0]?.errors?.length} remains unresolved!`
          );
        } else {
          //   toast.success("Payee detail updated successfully");
          setIsSuccess(true);
        }
        updateRowEntry(response?.result?.dataItems[0]);
        setSelectedRowData(response?.result?.dataItems[0]);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        if (error?.response) {
          toast.error(error?.response?.data?.message);
        }
      },
    }
  );

  // Handle Payout
  const handleUpdatePayeeDetails = async (formData: any) => {
    // Get all the supporting document in base64
    // let documentList = supportingDocument.map((doc) => doc.base64Format) || [];
    const newPayeeData = [
      {
        rowNumber: selectedRowData?.rowNumber,
        payeeId: formData?.payeeId,
        payeeName: formData?.payeeName,
        beneficiaryBankCode: formData?.beneficiaryBankCode,
        accountNumber: formData?.accountNumber,
        bank: selectedRowData?.bank,
        payableAmount: formData?.payableAmount,
        ...(payeeType === "Vendor"
          ? {
              vat: selectedTaxes.includes(1),
              whtGeneralContract: selectedTaxes.includes(2),
              whtConsultancyService: selectedTaxes.includes(3),
              stampDuty: selectedTaxes.includes(4),
            }
          : {}),
        reason: formData?.reason,
      },
    ];

    mutateAsync({
      payeeType: payeeType === "Vendor" ? 1 : 2,
      payeeDetails: newPayeeData,
    });
  };

  return (
    <SideDrawer
      isOpen={openDraw}
      setIsOpen={setOpenDrawer}
      showCloseButton={false}>
      {isSuccess ? (
        <StatusContainer
          heading={"Payee Detail Edited Successfully"}
          buttonText={"Done"}
          description={
            "The details uploaded has successfully been validated as correct!"
          }
          status={"success"}
          setOpenDrawer={setOpenDrawer}
          closeFunction={() => {
            closeDrawer();
            setIsSuccess(false);
          }}
          icon={
            <>
              <CheckMarkIcon />
              <GradientBackground />
            </>
          }
          showBackButton={false}
        />
      ) : (
        <div className="bulk-payout-review-container">
          <div className="sidedrawer-header">
            <h2>Payee details</h2>
            <button className="cancel-button" onClick={() => closeDrawer()}>
              <CloseOutlined className="x-icon !hidden md:!block" />
              <ArrowLeft className="arrow-icon !block md:!hidden" />
            </button>
          </div>
          {selectedRowData?.errorExists && (
            <>
              <div className="bg-[#FFF8EF] p-4 md:p-6 md:py-4 border-b border-b-[#F57E20]">
                <p className="text-caution font-medium text-lg flex items-center gap-2">
                  <span className="w-[18px] h-[18px]">
                    <CautionIcon className="w-[18px] h-[18px]" />
                  </span>
                  Error detected ({selectedRowData?.errors.length || 0})
                </p>
                <p className="text-[#0D0D0D99]">
                  We discovered error(s) in you upload
                </p>
              </div>
              <div className="p-4 md:p-6 md:py-4 space-y-2 border-b border-b-[#E8E8E8]">
                <p className="text-[#0D0D0D] text-base font-medium">
                  Ways to fix this error
                </p>
                <ul className="space-y-1 text-[#0D0D0D99] list-disc list-inside text-sm">
                  <li>
                    Update you file with the correct parameters and try again
                  </li>
                  <li>Ensure values are entered correctly </li>
                  <li>
                    Ensure Payee has been previously onboarded on the system
                  </li>
                </ul>
              </div>
            </>
          )}
          <form className="payout-content space-y-5 p-3.5 md:p-6 md:py-4">
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <CustomInput
                  placeholder="Enter payee's identification number"
                  value={watchPayeeId}
                  name="payeeId"
                  type="text"
                  label="Payee ID"
                  control={control}
                  errorMessage={
                    errors.payeeId?.message ||
                    selectedRowData?.errors.find(
                      (error) =>
                        error.propertyName.toLowerCase() ===
                        "payeeId".toLocaleLowerCase()
                    )?.errorComment ||
                    ""
                  }
                />
                <CustomInput
                  placeholder="Enter payee's name"
                  value={watchPayeeName}
                  name="payeeName"
                  type="text"
                  label="Payee name"
                  control={control}
                  errorMessage={
                    errors.payeeName?.message ||
                    selectedRowData?.errors.find(
                      (error) =>
                        error.propertyName.toLowerCase() ===
                        "payeeName".toLocaleLowerCase()
                    )?.errorComment ||
                    ""
                  }
                />
              </div>
              <div className="flex items-center gap-3">
                <CustomInput
                  placeholder="Enter payee's bank code"
                  value={watchBeneficiaryBankCode}
                  name="beneficiaryBankCode"
                  type="text"
                  label="Bank code"
                  control={control}
                  errorMessage={
                    errors.beneficiaryBankCode?.message ||
                    selectedRowData?.errors.find(
                      (error) =>
                        error.propertyName.toLowerCase() ===
                        "beneficiaryBankCode".toLocaleLowerCase()
                    )?.errorComment ||
                    ""
                  }
                />
                <CustomInput
                  placeholder="Enter payee's account number"
                  value={watchAccountNumber}
                  name="accountNumber"
                  type="text"
                  label="Account number"
                  control={control}
                  errorMessage={
                    errors.accountNumber?.message ||
                    selectedRowData?.errors.find(
                      (error) =>
                        error.propertyName.toLowerCase() ===
                        "accountNumber".toLocaleLowerCase()
                    )?.errorComment ||
                    ""
                  }
                />
              </div>
              <CustomInput
                placeholder="Enter amount to be paid"
                value={watchPayableAmount}
                name="payableAmount"
                type="number"
                label="Amount"
                control={control}
                errorMessage={
                  errors.payableAmount?.message ||
                  selectedRowData?.errors.find(
                    (error) =>
                      error.propertyName.toLowerCase() ===
                      "payableAmount".toLocaleLowerCase()
                  )?.errorComment ||
                  ""
                }
              />
              <CustomInput
                placeholder="Why are you making this payout?"
                value={watchReason}
                name="reason"
                type="text"
                label="Reason"
                control={control}
                errorMessage={
                  errors.reason?.message ||
                  selectedRowData?.errors.find(
                    (error) =>
                      error.propertyName.toLowerCase() ===
                      "reason".toLocaleLowerCase()
                  )?.errorComment ||
                  ""
                }
              />
              {/* <FileUpload
              supportingDocument={supportingDocument}
              setSupportingDocument={setSupportingDocument}
            /> */}
            </div>
            {payeeType === "Vendor" && (
              <div className="tax-container">
                <label className="label" htmlFor="tax">
                  Select all that applies
                </label>
                {isRetrievingTaxList ? (
                  <p className="label loading-tax-text">
                    Loading tax, please wait...
                  </p>
                ) : (
                  <Checkbox.Group
                    name="tax"
                    className="tax-selection"
                    options={taxList}
                    onChange={onChange}
                    value={selectedTaxes}
                  />
                )}
              </div>
            )}
            <div className="payout-form-control">
              <OutlineButton
                onClick={() => closeDrawer()}
                label={"Cancel"}
                className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block"
              />
              <PrimaryButton
                label={isSubmitingData ? <Spinner /> : "Update details"}
                disabled={isSubmitingData}
                className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block"
                onClick={handleSubmit(handleUpdatePayeeDetails)}
              />
            </div>
          </form>
        </div>
      )}
    </SideDrawer>
  );
};

export default BulkPayoutReviewSideDrawer;
