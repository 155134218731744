import { ReactComponent as CloseOutlined } from "../../assets/icons/cancelicon.svg";
import PrimaryButton from "../Button/PrimaryButton";
import OutlineButton from "../OutlineButton/OutlineButton";
import { Spinner } from "../../components/UIKits/Spinner/Spinner";
import "./BulkPayoutLayout.scss";

interface BulkPayoutLayoutType {
  children: React.ReactNode;
  pageHeading?: string;
  topCancelButtonAction: () => void;
  bottomCancelButtonAction: () => void;
  continueButtonAction: () => void;
  customCancelButtonText?: string;
  customContinueButtonText?: string;
  isContinueLoading?: boolean;
  disableContinue?: boolean;
  additionalClassName?: string;
  showButtomButtons?: boolean;
}

const BulkPayoutLayout = ({
  children,
  pageHeading = "New bulk payment",
  topCancelButtonAction,
  bottomCancelButtonAction,
  customCancelButtonText = "Cancel",
  customContinueButtonText = "Continue",
  continueButtonAction,
  isContinueLoading = false,
  disableContinue = false,
  additionalClassName = "",
  showButtomButtons = true,
}: BulkPayoutLayoutType) => {
  return (
    <div
      className={`bulk-payout-review relative w-11/12 mx-auto overflow-hidden ${additionalClassName}`}>
      <div className="w-11/12 mx-auto box-border flex gap-8 items-center fixed top-0 bg-blurry backdrop-blur-[2px] py-9 z-20">
        <button
          className="cancel-button rounded-full bg-[#F9F9F9]"
          onClick={topCancelButtonAction}>
          <CloseOutlined className="x-icon w-10 h-10" />
        </button>
        <h1 className="text-xl font-medium text-[#0D0D0D]">{pageHeading}</h1>
      </div>
      <div className="pb-28 pt-28">{children}</div>
      {showButtomButtons && (
        <div className="w-11/12 mx-auto box-border flex justify-end items-center gap-3 fixed left-0 right-0 bottom-0 bg-blurry backdrop-blur-[2px] py-9 z-20">
          <OutlineButton
            onClick={bottomCancelButtonAction}
            label={customCancelButtonText}
            disabled={isContinueLoading}
            className="font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm !bg-white"
          />
          <PrimaryButton
            onClick={continueButtonAction}
            label={isContinueLoading ? <Spinner /> : customContinueButtonText}
            disabled={isContinueLoading || disableContinue}
            className="font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm flex-row-reverse"
          />
        </div>
      )}
    </div>
  );
};

export default BulkPayoutLayout;
