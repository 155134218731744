import { FC, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Input, Button, Form } from "rsuite";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons/lib/icons";

import "./CustomInput.scss";

const CustomInput: FC<CustomInputProps> = ({
  leftIcon,
  placeholder,
  defaultValue,
  control,
  name,
  label,
  value,
  type,
  isDisabled,
  handleOnChange,
  inputStyles,
  errorMessage,
  inputContainerStyle,
  leftIconStyle,
  inputProps,
  maxLength,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className={`relative w-full ${inputContainerStyle}`}>
      {label && (
        <label className="w-[100%] label" htmlFor={name}>
          {label}
        </label>
      )}

      {leftIcon && (
        <div className={`absolute top-[13px] left-[15px] ${leftIconStyle}`}>
          {leftIcon}
        </div>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            id={name}
            type={type === "password" && showPassword ? "text" : type}
            value={value}
            onBlur={onBlur}
            onChange={(value) => {onChange(value); handleOnChange && handleOnChange(value)}}
            placeholder={placeholder}
            maxLength={maxLength}
            {...inputProps}
            className={`py-[10px] px-[12px] bg-transparent border border-[#F0F0F0] my-[6px] text-[#6A6A6A] rounded-[10px] text-sm font-normal relative leading-tight w-full ${inputStyles}
                ${
                  isDisabled && "bg-[#F0F0F0] border border-[#F0F0F0]"
                } focus:outline-none focus:ring-1 focus:ring-[#5F38FA] ${
              errorMessage && "border border-[#FF3B30] bg-[#ff3b300d]"
            } ${leftIcon && "pl-[36px]"}
              `}
            disabled={isDisabled}
          />
        )}
      />
      {type === "password" && (
        <div
          onClick={handleShowPassword}
          className="absolute top-[35px] right-[15px]"
        >
          {type === "password" && showPassword ? (
            <div
              className={
                type === "password" && showPassword
                  ? "show-password"
                  : "hide-password"
              }
            >
              <EyeOutlined />
            </div>
          ) : (
            <div
              className={
                type === "password" && showPassword
                  ? "hide-password"
                  : "show-password"
              }
            >
              <EyeInvisibleOutlined />
            </div>
          )}
        </div>
      )}
      {errorMessage && (
        <p className="text-[#FF3B30] text-[12px] first-letter:uppercase">{errorMessage}</p>
      )}
    </div>
  );
};

export default CustomInput;
