// import * as yup from "yup";

// export const manualBulkPayoutSchema = yup.object().shape({
//   payeeId: yup.string().required("Payee ID is required"),
//   payeeName: yup.string().required("Payee Name is required"),
//   accountNumber: yup.string().required("Account number is required"),
//   payableAmount: yup.number().required("Amount number is required"),
//   reason: yup.string().optional(),
// });

import * as yup from "yup";

export const manualBulkPayoutSchema = yup.object().shape({
  tableData: yup.array().of(
    yup.object().shape({
      payeeId: yup
        .object()
        .shape({
          value: yup
            .string()
            .required("Please select an option"),
        }),
      amountPayable: yup
        .number()
        .required("Payable amount is required")
        .min(0, "Payable amount must be greater than 0"),
      reason: yup.string().required("Reason is required"),
      // documents: yup.array().of(yup.string()).optional(),
      supportingDocument: yup
        .mixed()
        .required("Supporting document is required"),
      vat: yup.boolean(),
      whtGeneralContract: yup.boolean(),
      whtConsultancyService: yup.boolean(),
      stampDuty: yup.boolean(),
      amount: yup
        .number()
        .required("amount is required")
        .min(0, "amount must be greater than 0"),
    })
  ),
});
