import { useState } from "react";
import BulkPayoutLayout from "../../components/BulkPayoutLayout/BulkPayoutLayout";
import { useNavigate } from "react-router-dom";
import NotificationSwitch from "../../components/NotificationSwitch/NotificationSwitch";
import InitiateCard from "../../components/InitiateCard/InitiateCard";
import { useMutation } from "react-query";
import { downloadFile } from "../../utils/downloadFile";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import {
  bulkPayoutFileUpload,
  downloadBulkPayoutTemplate,
} from "../../services/bulk-payout.service";

interface UploadedFileType {
  file: File;
  name: string;
  size: number;
  type: string;
  base64Format: string;
}

const InitiateBulkPayout = () => {
  const [formType, setFormType] = useState("Vendor");
  const [bulkPayeeFile, setBulkPayeeFile] = useState<UploadedFileType[]>([]);

  const navigate = useNavigate();
  const handleSwitch = (filter: string) => {
    setFormType(filter);
  };

  const handleManualEntryNavigation = () => {
    navigate("/bulk-payout-manual-entry", {
      state: { payeeType: formType }, // Pass payeeType as state
    });
  };

  const { mutateAsync: mutateDownloadAsync } = useMutation(
    downloadBulkPayoutTemplate,
    {
      onSuccess: (response: any) => {
        downloadFile(response?.data, `${formType} Bulk Payout Template`);
        toast.success(
          `${formType} bulk upload file template download successful`
        );
      },
      onError: (error: AxiosError<{ message: string }>) => {
        if (error?.response?.status === 400) {
          toast.error("No data to download");
        } else {
          toast.error("Error downloading");
        }
      },
    }
  );

  const { mutate: uploadBulkPayoutFile, isLoading: isUploadingData } =
    useMutation(bulkPayoutFileUpload, {
      onSuccess: (response) => {
        if (
          response?.errors[0] ===
          "vat | whtgeneralcontract | whtconsultancyservice | stampduty header(s) not present in file uploaded."
        ) {
          toast?.error(`Invalid CSV file uploaded!`);
        } else if (response?.result?.dataItems?.length === 0) {
          toast?.error(`Invalid CSV file uploaded!`);
        } else {
          navigate("/bulk-payout-review", {
            state: {
              bulkPayoutReviewData: response,
              payeeType: formType,
            },
          });
        }
      },
      onError: (error: AxiosError<{ message: string }>) => {
        console.log(error);
        if (error?.response) {
          toast.error(error?.response?.data?.message);
        }else{
          toast.error("An error occurred, please try again later!")
        }
      },
    });

  const handleUploadSupportingDocument = async () => {
    let base64File = bulkPayeeFile.map((doc) => doc.base64Format)[0] || "";
    const payeeType = formType === "Vendor" ? 1 : 2;
    const payload = {
      base64File: base64File,
      payeeType: payeeType,
    };
    uploadBulkPayoutFile(payload);
  };

  const downloadTemplate = async () => {
    toast.success(`${formType} bulk upload file template download initated`);
    const PayeeType = formType === "Vendor" ? 1 : 2;
    await mutateDownloadAsync({ PayeeType });
  };

  return (
    <BulkPayoutLayout
      pageHeading="Initiate Bulk Payment"
      topCancelButtonAction={() => navigate("/bulk-payout")}
      bottomCancelButtonAction={() => navigate("/bulk-payout")}
      continueButtonAction={() => {
        handleUploadSupportingDocument();
      }}
      isContinueLoading={isUploadingData}
      disableContinue={bulkPayeeFile.length === 0}>
      <div className="flex flex-col items-center mt-10">
        <p className="text-2xl font-semibold text-[#0D0D0D] w-[95%] md:w-[300px] text-center">
          Who are you initiating this bulk payout for?
        </p>

        <p className="text-[#0d0d0d]/50 text-center text-base font-medium font-['Uncut Sans Variable'] leading-tight">
          Kindly select how you would like top make this payment
        </p>

        <div className="border border-[#ececec] w-full sm:w-[80%] xl:w-[50%] p-4 rounded-[18px] mt-6">
          <div className="mt-2 flex justify-center w-full">
            <NotificationSwitch
              firstSwitch="Vendor"
              secondSwitch="Employee"
              setFilter={handleSwitch}
              fullWidth={true}
            />
          </div>
          <div className="flex flex-row gap-4 mt-4 w-full">
            {bulkPayeeFile.length === 0 && (
              <InitiateCard
                label="Manual entry"
                handleClick={handleManualEntryNavigation}
                // handleClick={() => navigate("/bulk-payout-manual-entry")}
              />
            )}
            <InitiateCard
              label="Bulk upload"
              downloadTemplate
              handleClick={() => handleUploadSupportingDocument()}
              handleDownload={downloadTemplate}
              bulkPayeeFile={bulkPayeeFile}
              setBulkPayeeFile={setBulkPayeeFile}
            />
          </div>
        </div>
      </div>
    </BulkPayoutLayout>
  );
};

export default InitiateBulkPayout;
