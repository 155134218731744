import "./RenderIntialsFromVendorName.scss";
import { getVendorNameInitials } from "../../utils/getInitials";
import { BulkPayoutPopupRowData } from "../BulkPayoutDetailBreakdownPopup/types";
import BulkPayoutDetailBreakdownPopup from "../BulkPayoutDetailBreakdownPopup/BulkPayoutDetailBreakdownPopup";
import { useState } from "react";

interface TRenderIntialsFromVendorName {
  vendor_name: string;
  vendor_email?: string;
  payeeList?: BulkPayoutPopupRowData[] | null;
  customWidth?: number;
  customHeight?: number;
  isBulkPayout?: boolean;
  showViewMore?: boolean;
}

const RenderIntialsFromVendorName = ({
  vendor_name,
  vendor_email,
  isBulkPayout = false,
  payeeList,
  customWidth = 40,
  customHeight = 40,
  showViewMore = false,
}: TRenderIntialsFromVendorName) => {
  const splitBulkVendorName = isBulkPayout ? vendor_name?.split(",").map(item => item.trim()) : [];
  const [showBulkPayoutDetailPopup, setShowBulkPayoutDetailPopup] =
    useState(false);
  return !isBulkPayout ? (
    <div className="vendor-name flex items-center gap-2">
      <span className="initials-container">
        {getVendorNameInitials(vendor_name)}
      </span>
      <div>
        <p className="py-0 my-0"> {vendor_name}</p>
        <p className=" text-gray-400 py-0  my-0">
          {" "}
          {vendor_email ? vendor_email : null}{" "}
        </p>
      </div>
    </div>
  ) : (
    <div className="flex items-center">
      {splitBulkVendorName.map((payee, index) => {
        return (
          index < 3 && (
            <div
              className={`text-[13px] text-[#5F38FA] inline-flex items-center justify-center font-medium bg-[#6641FA0D] rounded-full uppercase ${
                index > 0 ? "-ml-2.5" : ""
              }`}
              style={{ width: customWidth, height: customHeight }}
              key={index}>
              {payee?.length > 2
                ? payee?.split(" ")[0].charAt(0) +
                payee?.split(" ")[1].charAt(0)
                : payee}
            </div>
          )
        );
      })}
      {splitBulkVendorName.length > 3 && (
        <div
          className={`-ml-2.5 text-sm text-[#0D0D0D] inline-flex items-center justify-center font-medium bg-white rounded-full border border-[#F1F1F1]`}
          style={{ width: customWidth, height: customHeight }}>
          +{splitBulkVendorName?.length - 3}
        </div>
      )}
      {showViewMore && (
        <>
          <span
            onClick={() => setShowBulkPayoutDetailPopup(true)}
            className="text-[#5F38FA] underline hover:opacity-80 ml-3 cursor-pointer">
            View details
          </span>
          <BulkPayoutDetailBreakdownPopup
            showPopup={showBulkPayoutDetailPopup}
            setShowPopup={setShowBulkPayoutDetailPopup}
            payeeList={payeeList}
          />
        </>
      )}
    </div>
  );
};

export default RenderIntialsFromVendorName;
