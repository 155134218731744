import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { ReactComponent as Magnifer } from "../../assets/icons/search.svg";
import { payoutStatuses } from "../../config/enum/common.enum";
import {
  FilterSelectionType,
  TPayout,
  TablePayoutData,
} from "../../pages/Payouts/types";
import { searchBulkPayout } from "../../services/payout-request";
import { getPayoutStatusText } from "../../utils/getPayoutStatusText";
import {
  capitalizeChargeKeys,
  formatCurrency,
  isNonNegativeNumber,
  totalTaxInfo,
} from "../../utils/helper";
import BottomSheet from "../BottomSheet/BottomSheet";
import MobileFilterContainer from "../MobileFilterContainer/MobileFilterContainer";
import OutlineButton from "../OutlineButton/OutlineButton";
import PayoutDetailDrawer from "../PayoutDetailDrawer/PayoutDetailDrawer";
import RenderIntialsFromVendorName from "../RenderIntialsFromVendorName/RenderIntialsFromVendorName";
import StatusSetter from "../StatusSetter/StatusSetter";
import CustomDropDown from "../UIKits/CustomDropdown/CustomDropdown";
import CustomInput from "../UIKits/CustomInput/CustomInput";
import DateRange from "../DateRange/DateRange";
import Table from "../Table/Table";
import Pagination from "../Pagination/Pagination";
import { subDays } from "rsuite/esm/internals/utils/date";
import "./BulkPayoutTable.scss";
import { chargeDataType } from "../PayoutCreationDrawer/types";
import TaxBreakDownModal from "../../components/PayoutTable/TaxBreakDownModal/TaxBreakDownModal";
import TaxBreakDownText from "../../components/PayoutTable/TaxBreakDownText/TaxBreakDownText";
import { payeeStatuses } from "../../config/enum/common.enum";
import BulkPayoutDetailDrawer from "../BulkPayoutDetailsDrawer/BulkdPayoutDetailDrawer";


const BulkPayoutTable = ({
  numberOfRows,
  showPagination = true,
  vendorId,
  setDownloadFileFilterValues,
}: {
  numberOfRows?: number;
  showPagination?: boolean;
  vendorId?: number;
  setDownloadFileFilterValues?: (filter: TSearchParams) => void;
}) => {
  const [showPayoutDetailDrawer, setShowPayoutDetailDrawer] = useState(false);
  const [showCustomDateBottomSheet, setShowCustomDateBottomSheet] =
    useState(false);
  const [showOtherFilterBottomSheet, setShowOtherFilterBottomSheet] =
    useState(false);

  const [status, setStatus] = useState({
    label: "All",
    value: -1,
  });

  const [payee, setPayee] = useState({
    label: "All",
    value: -1,
  });
  const [selectedPayoutToShow, setSelectedPayoutToShow] =
    useState<TPayout | null>(null);

  const tableHead: { name: keyof TablePayoutData; displayName: string }[] = [
    { name: "vendorName", displayName: "Payee" },
    { name: "amount", displayName: "Amount" },
    { name: "taxPayable", displayName: "Tax payable" },
    { name: "amountPayable", displayName: "Amount payable" },
    { name: "status", displayName: "Status" },
    { name: "createdOn", displayName: "Date" },
    { name: "transactionId", displayName: "Transaction Id" },
  ];

  const [searchParams, setSearchParams] = useState<TSearchParams>({
    PageSize: 10,
    Page: 1,
    StartDate: moment().subtract(30, "days").format("yy-MM-DD"),
    EndDate: moment().format("yy-MM-DD"),
  });
  const { control, watch } = useForm({
    defaultValues: { searchText: "" },
  });
  const searchText = watch("searchText");
  const [filters, setFilters] = useState({});

  const optionsData = [
    {
      type: "status",
      options: payoutStatuses,
    },
    {
        type: "payee",
        options: payeeStatuses,
      },
    {
      type: "date",
      options: [
        { label: "Today", value: [new Date(), new Date()] },
        { label: "Yesterday", value: [subDays(new Date(), 1), new Date()] },
        { label: "Last 7 days", value: [subDays(new Date(), 6), new Date()] },
        { label: "Last 30 days", value: [subDays(new Date(), 29), new Date()] },
        { label: "Last 90 days", value: [subDays(new Date(), 89), new Date()] },
        {
          label: "Last 365 days",
          value: [subDays(new Date(), 364), new Date()],
        },
        { label: "Custom", value: [] },
      ],
    },
  ];
  const { isLoading, data } = useQuery(
    [
      "getAllPayouts",
      {
        filters,
      },
    ],
    () => searchBulkPayout(filters),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {},
      onError: (err) => {},
    }
  );

  const handleDateSelect = (date: [Date, Date]) => {
    setSearchParams({
      ...searchParams,
      StartDate: moment(date[0]).format("yy-MM-DD"),
      EndDate: moment(date[1]).format("yy-MM-DD"),
    });
  };

  const applyFilter = (params: FilterSelectionType) => {
    const dateValue = params?.date?.value as Date[];
    const StartDate = dateValue
      ? moment(dateValue[0]).format("yy-MM-DD")
      : moment().subtract(30, "days").format("yy-MM-DD");
    const EndDate = dateValue
      ? moment(dateValue[1]).format("yy-MM-DD")
      : moment().format("yy-MM-DD");
    // EndDate: moment(date[1]).format("yy-MM-DD"),'
    const newFilters = {
      StartDate,
      EndDate,
      Status: isNonNegativeNumber(params?.status?.value)
        ? params?.status?.value
        : null,
      TextFilter: searchText || "",
      PayeeType: isNonNegativeNumber(params?.payee?.value)
      ? params?.payee?.value
      : null,
    };
    setFilters(newFilters);
    setShowOtherFilterBottomSheet(false);
    setShowCustomDateBottomSheet(false);
  };

  const showCustomDateFilter = () => {
    setShowCustomDateBottomSheet(true);
  };

  const handlePageChange = useCallback(
    (page: number) => {
      setSearchParams({
        ...searchParams,
        Page: page,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  );

  useEffect(() => {
    const newFilters = {
      ...searchParams,
      Status: isNonNegativeNumber(status.value) ? status.value : null,
      TextFilter: searchText || "",
      PayeeType: isNonNegativeNumber(payee.value) ? payee.value : null,
    };
    if (vendorId) {
      newFilters.VendorId = vendorId;
    }
    setFilters(newFilters);
    setDownloadFileFilterValues && setDownloadFileFilterValues(newFilters);
  }, [searchParams, status, searchText, payee]);

  const [showTaxModal, setShowTaxModal] = useState(false);

  const handleShowTaxBreakDownModal = () => {
    setShowTaxModal(true);
  };

  const handlePopupClick = (
    event: React.MouseEvent<HTMLDivElement>,
    data: TPayout | null
  ) => {
    event.stopPropagation();
    setSelectedPayoutToShow(data);
    setShowTaxModal(true);
  };


  console.log(data?.items, "items");
  
  return (
    <section>
      <div className="table-container-wrapper">
        <div className="filter-search-container lg:flex-row xs:flex-col">
          <OutlineButton
            icon={<Filter />}
            className="font-medium !px-2.5 !py-1.5 lg:!hidden"
            onClick={() => setShowOtherFilterBottomSheet(true)}
          />
          <div className="flex gap-5">
            <div className="large-status-filter">
              <CustomDropDown
                dataItems={payeeStatuses}
                dropdownLabel="Payee"
                isSearchable={false}
                isClearable={false}
                selectedOption={payee}
                setSelectedOption={setPayee}
              />
            </div>
            <div className="large-status-filter">
              <CustomDropDown
                dataItems={payoutStatuses}
                dropdownLabel="Status"
                isSearchable={false}
                isClearable={false}
                selectedOption={status}
                setSelectedOption={setStatus}
              />
            </div>
            <div className="large-status-filter">
              <DateRange handleDateSelect={handleDateSelect} />
            </div>
          </div>

          <div className="large-status-filter w-[30%]">
            <CustomInput
              leftIcon={<Magnifer />}
              placeholder="Filter by name or transaction id"
              name="searchText"
              control={control}
              type="text"
            />
          </div>
        </div>
        <div className="main-table-wrapper">
          <Table<TablePayoutData, TPayout>
            fields={tableHead}
            tableData={data?.items ?? []}
            handleRowClick={(item) => {
              setSelectedPayoutToShow(item);
              setShowPayoutDetailDrawer(true);
            }}
            isLoading={isLoading}
            rows={numberOfRows}
            builder={(field, data) => {
              switch (field?.name) {
                case "vendorName":
                  return (
                    <RenderIntialsFromVendorName
                      vendor_name={data?.payees || ""}
                      isBulkPayout={true}

                    />
                  );
                case "createdOn":
                  return (
                    <div>{moment(data?.createdOn).format("DD MMM YYYY")}</div>
                  );
                case "amount":
                  return <div>{formatCurrency(data?.amount)}</div>;
                case "taxPayable":
                  return (
                    <div className="flex items-center gap-1.5">
                      {formatCurrency(data?.taxPayable)}
                      {data?.taxPayable !== 0 && (
                        <span
                          className="h-fit w-fit"
                          onClick={(
                            event: React.MouseEvent<HTMLDivElement>
                          ) => {
                            handlePopupClick(event, data);
                          }}>
                          <TaxBreakDownText
                            handleModalPopup={handleShowTaxBreakDownModal}
                            taxChargeList={
                              capitalizeChargeKeys(data?.charges) || []
                            }
                          />
                        </span>
                      )}
                    </div>
                  );
                case "amountPayable":
                  return (
                    <div>{formatCurrency(data?.amount - data?.taxPayable)}</div>
                  );
                case "status":
                  return (
                    <StatusSetter
                      incomingStatus={data?.status}
                      statusLabel={getPayoutStatusText(data?.status)}
                    />
                  );
                default:
                  return data[field?.name];
              }
            }}
          />
        </div>

        {data?.totalCount > 0 && showPagination && (
          <div className="w-full ">
            <Pagination
              totalItems={data?.totalCount}
              itemsPerPage={searchParams.PageSize}
              onPageChange={handlePageChange}
              currentPage={searchParams.Page}
            />
          </div>
        )}
      </div>

      {selectedPayoutToShow && (
        <BulkPayoutDetailDrawer
          setOpenDrawer={setShowPayoutDetailDrawer}
          openDraw={showPayoutDetailDrawer}
          selectedPayoutToShow={selectedPayoutToShow}
        />
      )}
      {selectedPayoutToShow?.charges && selectedPayoutToShow?.taxPayable && (
        <TaxBreakDownModal
          amountPaid={selectedPayoutToShow?.amount}
          amountTaxable={selectedPayoutToShow?.taxPayable}
          taxChargeList={
            capitalizeChargeKeys(selectedPayoutToShow?.charges) || []
          }
          showTaxBreakDownModal={showTaxModal}
          setShowTaxBreakDownModal={setShowTaxModal}
        />
      )}
      <BottomSheet
        title={showCustomDateBottomSheet ? "Custom Selection" : "Filter"}
        openBottomSheet={showOtherFilterBottomSheet}
        setOpenBottomSheet={setShowOtherFilterBottomSheet}
        onCloseAction={() => {
          showCustomDateBottomSheet && setShowCustomDateBottomSheet(false);
        }}>
        <MobileFilterContainer
          setShowOtherFilterBottomSheet={setShowOtherFilterBottomSheet}
          optionsData={optionsData}
          applyFilterAction={applyFilter}
          customizedOptionAction={showCustomDateFilter}
        />
      </BottomSheet>
    </section>
  );
};

export default BulkPayoutTable;
