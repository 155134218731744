import { Modal } from "antd";
import OutlineButton from "../OutlineButton/OutlineButton";
import { BulkPayoutPopupRowData, TableBulkPayoutPopupData } from "./types";
import { ReactComponent as DocumentIcon } from "../../assets/icons/documenticon2.svg";
import Table from "../Table/Table";
import RenderIntialsFromVendorName from "../RenderIntialsFromVendorName/RenderIntialsFromVendorName";
import { capitalizeChargeKeys, formatCurrency } from "../../utils/helper";
import TaxBreakDownText from "../PayoutTable/TaxBreakDownText/TaxBreakDownText";
import { useState } from "react";
import SupportDocumentModal from "../SupportDocumentModal/SupportDocumentModal";
import RenderBankWithIcon from "../PayoutDetailDrawer/RenderBankWithIcon/RenderBankWithIcon";
import TaxBreakDownModal from "../PayoutTable/TaxBreakDownModal/TaxBreakDownModal";

interface BulkPayoutDetailBreakdownPopupType {
  showPopup: boolean;
  setShowPopup: (showPopup: boolean) => void;
  payeeList?: BulkPayoutPopupRowData[] | null;
}

const BulkPayoutDetailBreakdownPopup = ({
  showPopup,
  setShowPopup,
  payeeList,
}: BulkPayoutDetailBreakdownPopupType) => {
  const [showTaxModal, setShowTaxModal] = useState(false);

  const handleShowTaxBreakDownModal = () => {
    setShowTaxModal(true);
  };
  const [showSupportingDocumentModal, setShowSupportingDocumentModal] =
    useState(false);
  const [selectedDataToShow, setSelectedDataToShow] =
    useState<BulkPayoutPopupRowData | null>(null);

  const tableHead: {
    name: keyof TableBulkPayoutPopupData;
    displayName: string;
  }[] = [
    { name: "vendorName", displayName: "Name" },
    { name: "amount", displayName: "Amount" },
    { name: "taxPayable", displayName: "Tax payable" },
    { name: "payableAmount", displayName: "Amount payable" },
    { name: "bankName", displayName: "Bank name" },
    { name: "accountName", displayName: "Account name" },
    { name: "accountNumber", displayName: "Account number" },
    { name: "reason", displayName: "Reason" },
    { name: "documentLink", displayName: "Support document" },
  ];

  return (
    <Modal
      title={<p className="text-2xl font-semibold">Vendor details</p>}
      open={showPopup}
      onCancel={() => {
        setShowPopup(false);
        // setActiveRow(null);
      }}
      width={"80%"}
      onOk={() => setShowPopup(false)}
      footer={null}>
      <div className="main-table-wrapper my-10">
        <Table<TableBulkPayoutPopupData, BulkPayoutPopupRowData>
          fields={tableHead}
          tableData={payeeList || []}
          //   handleRowClick={(item: BulkPayoutReviewRowData) => {
          //     setActiveRow(item);
          //     setShowEditModal(true);
          //   }}
          // isLoading={isPersonalPayoutLoading}
          //   rows={bulkPayoutData?.result?.dataItems?.length || 0}
          builder={(field, data) => {
            switch (field.name) {
              case "vendorName":
                return (
                  <RenderIntialsFromVendorName vendor_name={data.vendorName} />
                );
              case "amount":
                return <span>{formatCurrency(data.amount)}</span>;
              case "taxPayable":
                return (
                  <div className="flex items-center gap-1.5">
                    {formatCurrency(data?.taxPayable)}
                    {data.taxPayable !== 0 && (
                      <span
                        className="h-fit w-fit"
                        onClick={() => {
                          setSelectedDataToShow(data);
                          setShowTaxModal(true);
                        }}>
                        <TaxBreakDownText
                          handleModalPopup={handleShowTaxBreakDownModal}
                          taxChargeList={
                            capitalizeChargeKeys(data?.charges) || []
                          }
                        />
                      </span>
                    )}
                  </div>
                );
              case "payableAmount":
                return (
                  <div>{formatCurrency(data.amount - data.taxPayable)}</div>
                );
              case "bankName":
                return (
                  <span>
                    <RenderBankWithIcon bankName={data?.bankName} />
                  </span>
                );
              case "accountName":
                return <span>{data?.accountName}</span>;
              case "accountNumber":
                return <span>{data?.accountNumber}</span>;
              case "reason":
                return (
                  <span className="block text-wrap w-[140px]">
                    {data?.reason}
                  </span>
                );
              case "documentLink":
                return data?.documentLink === null ? (
                  <span className="w-full flex items-center justify-center">
                    ---
                  </span>
                ) : (
                  <OutlineButton
                    onClick={() => {
                      setShowSupportingDocumentModal(true);
                      setSelectedDataToShow(data);
                    }}
                    icon={<DocumentIcon />}
                    label={"Click to view"}
                    className="font-medium !py-1.5 text-nowrap !text-sm "
                  />
                );

              default:
                return data[field?.name];
            }
          }}
        />
      </div>
      {selectedDataToShow && setShowSupportingDocumentModal && (
        <SupportDocumentModal
          showSupportingDocumentModal={showSupportingDocumentModal}
          setShowSupportingDocumentModal={setShowSupportingDocumentModal}
          documentLink={selectedDataToShow?.documentLink || ""}
        />
      )}
      {selectedDataToShow && showTaxModal && (
        <TaxBreakDownModal
          amountPaid={selectedDataToShow?.amount}
          amountTaxable={selectedDataToShow?.taxPayable}
          taxChargeList={
            capitalizeChargeKeys(selectedDataToShow?.charges) || []
          }
          showTaxBreakDownModal={showTaxModal}
          setShowTaxBreakDownModal={setShowTaxModal}
        />
      )}
    </Modal>
  );
};

export default BulkPayoutDetailBreakdownPopup;
